import React, { useState, useEffect } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from 'gatsby';
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import $ from "jquery"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import Share from "../share/share"
import GetGGFXImage from "../common/site/get-ggfx-image"
import NoImage from "../../images/no-image.png"
import './assets/styles/_index.scss';
import { SaveItem, useUserObjectState, useAuthState } from "@starberry/myaccount-website-utils";
import { isMobileOnly } from 'react-device-detect';



const PropertyDetailsSlider = (props) => {

    const { state, services } = useUserObjectState()
    const { services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()

    useEffect(() => {
        if (isAuthenticated) {
        // on page load get the users saved objects
            services.getUserobject({ type: "property", state:true })
        }
    }, [])


    // Slider settings
    let settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false
    }
    // Slider settings

    let processedImages = JSON.stringify({});
    if (props?.propertyData?.imagetransforms?.images_Transforms) {
      processedImages = props.propertyData.imagetransforms.images_Transforms;
    }

    // Slider count
    const [indexed, setIndex] = useState(1)
    const afterChangeHandler = (d) => {
        setIndex(d + 1)
    }
    // Slider count

    // Property map
    const propertyMap = () => {
        $("html, body").animate(
            {
              scrollTop: $("#property-details-map").offset().top - 150,
            },500
        )
    }
    // Property map

    // Property images lightbox
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const openPropertyImage = (e, ind) => {
        e.preventDefault()
        setPhotoIndex(ind)
        setIsOpen(true)
    }
    // Property images lightbox

    // Property details images lightbox
    const propertyImages = props?.propertyData?.images
    var propertyLightImages =
        propertyImages && propertyImages.map(img => img.srcUrl)
    // Property details images lightbox

    // Floorplan images lightbox
    const [floorIndex, setFloorIndex] = useState(0)
    const [isOpenFloor, setIsOpenFloor] = useState(false)

    const openFloorplanImage = (e, ind) => {
        e.preventDefault()
        setFloorIndex(ind)
        setIsOpenFloor(true)
    }

    const floorplanImages = props?.propertyData?.floorplan
    var floorplanLightImages =
        floorplanImages && floorplanImages.map(floorImg => floorImg.url)
    // Epc images lightbox


       // Epc images lightbox
       const [epcIndex, setEpcIndex] = useState(0)
       const [isOpenEpc, setIsOpenEpc] = useState(false)
   
       const openEpcImage = (e, ind) => {
           e.preventDefault()
           setEpcIndex(ind)
           setIsOpenEpc(true)
       }
   
       const epcImages = props?.propertyData?.epc
       var epcLightImages =
       epcImages && epcImages.map(epcImg => epcImg.url)
       // Epc images lightbox

       const shareurl = typeof window !== 'undefined' ? window.location.href : ''
       const trackerEvent = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': event,
            'formType': event + ' - ' +shareurl,
            'formId': event,
            'formName': event,
            'formLabel': event
        });    
        }


    return (
        <section className="property-slider-wrapper">
            <Container>
                <Row>
                    <Col lg={4} className="order-lg-1 order-2">
                        {
                            props?.propertyData?.display_address &&
                            <h1>{props?.propertyData?.display_address}</h1>
                        }
                        {
                            props?.propertyData?.price_qualifier === "Price On Application" ?
                                <div className="proeprty-price">{props?.propertyData?.price_qualifier}</div>
                            :
                            props?.propertyData?.price &&
                            (
                                props?.propertyData?.search_type === "sales" ?
                                <div className="proeprty-price">{props?.propertyData?.price_qualifier} £{props?.propertyData?.price?.toLocaleString()}</div>
                                :
                                <div className="proeprty-price">£{props?.propertyData?.price?.toLocaleString()} {props?.propertyData?.price_qualifier === "Weekly" ? "per week" : (props?.propertyData?.price_qualifier === "Monthly" ? "per month" : props?.propertyData?.price_qualifier)}</div>
                            )
                        }
                        <ul className="list-inline property-details-facilities">
                            {
                                props?.propertyData?.bedroom != 0 && props?.propertyData?.bedroom &&
                                <li className="list-inline-item"><i className="icon icon-bedroom"></i> {props?.propertyData?.bedroom}</li>
                            }
                            {
                                props?.propertyData?.bathroom != 0 && props?.propertyData?.bathroom &&
                                <li className="list-inline-item"><i className="icon icon-bathroom"></i> {props?.propertyData?.bathroom}</li>
                            }
                            {
                                 props?.propertyData?.reception != 0 && props?.propertyData?.reception &&
                                <li className="list-inline-item"><i className="icon icon-reception"></i> {props?.propertyData?.reception}</li>
                            }
                        </ul>
                        <div className="property-btn-wrapper">
                            <div className="d-md-block d-none">
                                {
                                    props?.propertyData?.status === "Sold" ||
                                    props?.propertyData?.status === "Sold Subject to Contract" ||
                                    props?.propertyData?.status === "Completed" ||
                                    props?.propertyData?.status === "Exchanged" ||
                                    props?.propertyData?.status === "Let" ? 
                                    <Link to={"/contact-estate-agents-in-london/"} className="btn btn-primary-outline">Contact Us</Link>
                                    : 
                                    <Link to={"/book-a-viewing?pid="+props?.propertyData?.id} className="btn btn-primary-outline">Enquire Now</Link>
                                }                                
                                <div className="call-us">or call us on <a href="tel:+44 (0)20 7723 9988">+44 (0)20 7723 9988</a></div>
                            </div>
                            <div className="d-md-none d-flex justify-content-between">
                                {
                                    props?.propertyData?.status === "Sold" ||
                                    props?.propertyData?.status === "Sold Subject to Contract" ||
                                    props?.propertyData?.status === "Completed" ||
                                    props?.propertyData?.status === "Exchanged" ||
                                    props?.propertyData?.status === "Let" ? 
                                    <Link to={"/contact-estate-agents-in-london/"} className="btn btn-primary-outline me-2">Contact Us</Link>
                                    :
                                    <Link to={"/book-a-viewing?pid="+props?.propertyData?.id} className="btn btn-primary-outline me-2">Enquire Now</Link>
                                }
                                <a href="tel:+44 (0)20 7723 9988" className="btn btn-white-outline">Call</a>
                            </div>
                        </div>
                        <ul className="m-0 p-0 property-details-share">
                            <li className="">
                                <SaveItem type="property" pid={props?.propertyData?.id}>
                                    <a className='show-search' onClick={()=>trackerEvent("Save property")} >
                                        <i className="icon icon-save"></i>Save Property
                                    </a>
                                    <a className='remove-search' onClick={()=>trackerEvent("Save property")}>
                                        <i className="icon icon-heart-fill"></i> Saved Property
                                    </a>
                                </SaveItem>
                            </li>
                            {
                                props?.propertyData?.brochure?.length > 0 &&
                                <li className="">
                                    <a onClick={()=>trackerEvent("Property Detail - Download Brochure")} href={props?.propertyData?.brochure[0]?.url} target="_blank"><i className="icon icon-print"></i> Print Details</a>
                                </li>
                            }
                            <li className="news-detail-wrapper">
                                <div className="news-head">
                                    <div className="news-share">
                                        <Share iconclass="icon-share" text="Share this Property" id={props?.propertyData?.id} />
                                    </div>
                                </div>                                
                            </li>
                        </ul>
                    </Col>
                    <Col lg={1} className="order-lg-2"></Col>
                    <Col lg={7} className="order-lg-3 order-1">
                        <div className="property-details-slider">
                            <Slider {...settings}
                                afterChange={
                                    v => afterChangeHandler(v)
                                }
                            >
                                {
                                    props?.propertyData?.images?.length > 0 ? (
                                        props?.propertyData?.images?.map((imgItem, i) => {
                                            return (
                                                <picture className="property-details-img" key={i}>
                                                    <GetGGFXImage 
                                                        imagename={"property.images.detail"}
                                                        imagesource={imgItem}
                                                        fallbackalt={props?.propertyData?.display_address}
                                                        imagetransformresult={processedImages}
                                                        id={imgItem?.id}
                                                    />
                                                </picture>
                                            )
                                        })
                                    )
                                    :
                                    (
                                        <picture className="property-details-img">
                                            <img src={NoImage} alt="Banner Img" />
                                        </picture>
                                    )
                                }
                            </Slider>
                            <ul className="list-inline property-slider-options">
                                {
                                    props?.propertyData?.images?.length > 0 &&
                                    <li className="list-inline-item"><a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)} className="d-flex align-items-center justify-content-center"><i className="icon icon-zoom"></i><span>{indexed} / {props?.propertyData?.images?.length} Photos</span></a></li>
                                }
                                {
                                    props?.propertyData?.latitude && props?.propertyData?.longitude && (
                                    <li className={`list-inline-item ${isMobileOnly ? 'text-none' : ''}`}><a href="javascript:void(0)" onClick={propertyMap} className="d-flex align-items-center justify-content-center"><i className="icon icon-loc"></i><span>Location</span></a></li>
                                    )
                                }
                                {
                                    props?.propertyData?.floorplan?.length > 0 &&
                                    <li className={`list-inline-item ${isMobileOnly ? 'text-none' : ''}`}><a href="javascript:void(0)" onClick={e => openFloorplanImage(e, 0)} className="d-flex align-items-center justify-content-center"><i className="icon icon-floorplan"></i><span>Floorplan</span></a></li>
                                }
                                {
                                    props?.propertyData?.epc?.length > 0 &&
                                    <li className={`list-inline-item ${isMobileOnly ? 'text-none' : ''}`}><a href="javascript:void(0)" onClick={e => openEpcImage(e, 0)} className="d-flex align-items-center justify-content-center text-uppercase"><i className="icon icon-epc"></i><span>Epc</span></a></li>
                                }
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Banner image popup */}
            {isOpen && (
                <Lightbox
                    mainSrc={propertyLightImages[photoIndex]}
                    nextSrc={
                        propertyLightImages[
                        (photoIndex + 1) % propertyLightImages.length
                        ]
                    }
                    prevSrc={
                        propertyLightImages[
                        (photoIndex + propertyLightImages.length - 1) %
                            propertyLightImages.length
                        ]
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                        (photoIndex + propertyLightImages.length - 1) %
                            propertyLightImages.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
                    }
                />
            )}
            {/* Banner image popup */}

            {/* Floorplan image popup */}
            {isOpenFloor && (
                <Lightbox
                mainSrc={floorplanLightImages[floorIndex]}
                nextSrc={
                    floorplanLightImages[
                    (floorIndex + 1) % floorplanLightImages.length
                    ]
                }
                prevSrc={
                    floorplanLightImages[
                    (floorIndex + floorplanLightImages.length - 1) %
                        floorplanLightImages.length
                    ]
                }
                onCloseRequest={() => setIsOpenFloor(false)}
                onMovePrevRequest={() =>
                    setFloorIndex(
                    (floorIndex + floorplanLightImages.length - 1) %
                        floorplanLightImages.length
                    )
                }
                onMoveNextRequest={() =>
                    setFloorIndex((floorIndex + 1) % floorplanLightImages.length)
                }
                />
            )}
            {/* Floorplan image popup */}



            {/* EpC image popup */}
            {isOpenEpc && (
                <Lightbox
                mainSrc={epcLightImages[epcIndex]}
                nextSrc={
                    epcLightImages[
                    (epcIndex + 1) % epcLightImages.length
                    ]
                }
                prevSrc={
                    epcLightImages[
                    (epcIndex + epcLightImages.length - 1) %
                    epcLightImages.length
                    ]
                }
                onCloseRequest={() => setIsOpenEpc(false)}
                onMovePrevRequest={() =>
                    setEpcIndex(
                    (epcIndex + epcLightImages.length - 1) %
                    epcLightImages.length
                    )
                }
                onMoveNextRequest={() =>
                    setEpcIndex((epcIndex + 1) % epcLightImages.length)
                }
                />
            )}
            {/* EpC image popup */}


        </section>
    )
}

export default PropertyDetailsSlider